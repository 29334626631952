.site-footer {
  padding: 170px 0 35px;
  background-color: $color-gray-500;
  color: $color-wht;

  @include mobile {
    padding: 53px 0 36px;
  }

  &__container {
    max-width: 1350px;
    padding: 0 25px;
    margin: auto;

    @include mobile {
      padding: 0 40px;
    }
  }

  &__2cols {
    display: flex;

    @include mobile {
      display: block;
    }

    .site-footer__col:first-of-type {
      padding-bottom: 55px;
    }
  }

  &__col {
    width: 50%;

    @include mobile {
      width: auto;
    }
  }

  .footer-address {
    margin-bottom: 105px;
    font-style: normal;

    @include mobile {
      margin-bottom: 30px;
    }

    &__logo {
      max-width: 530px;
      padding-right: 40px;
      margin-bottom: 25px;

      @include mobile {
        padding-right: 0;
        margin-bottom: 13px;
      }
    }

    &__address {
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 5px;

      @include mobile {
        font-size: 1.1rem;
        margin-bottom: 3px;
      }
    }

    &__tel {
      font-size: 1.8rem;
      font-weight: 400;
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.7;
      }

      @include mobile {
        font-size: 1.4rem;
      }
    }
  }

  .footer-nav {
    font-size: 1.8rem;
    letter-spacing: 0.1em;

    @include mobile {
      font-size: 1.5rem;
    }

    &__list {
      @include mobile {
        margin-right: -20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;
        gap: 20px;
      }
    }

    &__item {
      + .footer-nav__item {
        margin-top: 20px;

        @include mobile {
          margin-top: 0;
        }
      }
    }

    &__link {
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  @include mobile {
    .office-hour,
    .official-sns {
      position: relative;
      padding: 35px 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .footer-title {
    font-size: 3.6rem;
    font-family: $font__cinzel;

    @include mobile {
      font-size: 2.4rem;
    }

    &__sub {
      font-size: 1.6rem;

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  .office-hour {
    margin-bottom: 70px;

    @include mobile {
      margin-bottom: 0;
    }

    &__head {
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 22px;
      }
    }
  }

  .schedule {
    width: 100%;
    font-size: 1.6rem;
    margin-bottom: 35px;
    border-spacing: 0;

    @include mobile {
      font-size: 1.2rem;
      margin-bottom: 15px;
    }

    &__head-th,
    &__body-th {
      width: 104px;
      font-weight: 500;

      @include mobile {
        width: 80px;
      }
    }

    &__head-th,
    &__head-td {
      height: 40px;

      @include mobile {
        height: 20px;
      }
    }

    &__head-td,
    &__body-td {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      text-align: center;
      font-weight: 400;
    }

    &__body-td {
      color: $color-gold-500;
    }

    &__body-th,
    &__body-td {
      height: 50px;

      @include mobile {
        height: 25px;
      }
    }
  }

  .schedule-defi-list {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2;

    @include mobile {
      font-size: 0.9rem;
    }

    &__item {
      display: flex;
    }

    &__term {
      min-width: max-content;
    }
  }

  .official-sns {
    &__head {
      margin-bottom: 15px;
    }

    &__desc {
      margin-top: 20px;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2;

      @include mobile {
        font-size: 0.9rem;
      }
    }
  }

  .copy-right {
    max-width: 1400px;
    padding: 0 25px;
    margin: 100px auto 0;
    text-align: right;
    font-size: 1.2rem;

    @include mobile {
      text-align: center;
      margin: 40px auto 0;
      font-size: 0.9rem;
    }
  }
}
