.site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;

  &__logo {
    position: absolute;
    top: 48px;
    left: 32px;
    z-index: 1;
    width: min(279px, 18.6vw);
    transform-origin: bottom;

    @include mobile {
      width: 140px;
      top: 10px;
      left: 10px;
    }
  }

  .logo__link {
    display: block;
    height: 100%;
    transition: transform 0.5s;

    &:hover,
    &:focus {
      transform: scale(0.98);
    }
  }

  .header-nav {
    position: fixed;
    top: 40px;
    right: min(62px, 4.1333vw);
    z-index: 1;

    @include mobile {
      display: none;
    }

    &__list {
      display: flex;
      color: $color-brown-400;
      // font-size: clamp(1rem, 0.9333vw, 1.4rem);
      letter-spacing: 0.1em;
    }

    &__item {
      transform-origin: bottom;

      + .header-nav__item {
        margin-left: min(31px, 2.0667vw);
      }
    }

    &__link {
      font-size: clamp(1.2rem, 0.9333vw, 1.4rem);
      // font-size: 1.4rem;
      // padding: 5px 2px 5px;
      position: relative;
      display: block;
      overflow: hidden;

      .word {
        padding: 5px 2px 5px;
        display: block;

        &_dammy {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 0;
        }
      }
    }

    &__line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  }

  .sp-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100vh;
    color: $color-wht;
    transform: translate(0%, 0);
    transition: transform 0.5s $easeOutExpo;
    visibility: hidden;

    &__bg {
      position: fixed;
      background-color: $color-gray-500;
      width: max(300vw, 300vh);
      height: max(300vw, 300vh);
      border-radius: 50%;
      top: 0;
      right: 0;
      z-index: 0;
      transform: translate(50%, -50%) scale(0);
    }

    &__content-wrap {
      overflow-y: auto;
      height: 100vh;
    }

    &__content {
      min-height: 100vh;
      padding: 65px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
    }

    &__inner {
      width: 100%;
    }
  }

  .sp-nav {
    font-size: 2rem;
    letter-spacing: 0.18em;
    margin-bottom: 50px;

    &__item {
      + .sp-nav__item {
        margin-top: 20px;
      }
    }

    &__link {
      display: inline-block;
    }
  }

  .sp-others {
    .sp-others__item {
      &:first-of-type {
        margin-bottom: 35px;
      }

      &:nth-of-type(2) {
        margin-bottom: 55px;
      }
    }

    &__term {
      font-size: 1.2rem;
      margin-bottom: 8px;
      font-family: $font__cinzel;
    }

    &__reservation-btn {
      justify-content: start;
      font-size: 1.7rem;
      padding: 0.5em 1.25em;
    }

    &__tel {
      font-family: $font__cinzel;
      font-size: 1.8rem;
      letter-spacing: 0.06em;
    }

    &__sns-list {
      margin-top: 15px;
    }
  }

  .hamburger-btn {
    display: none;

    @include mobile {
      display: inline-block;
      padding: 16px 7px;
      width: 44px;
      height: 45px;
      position: fixed;
      top: 20px;
      right: 23px;
      z-index: 3;
      transition: 0.4s;

      &__inner {
        display: block;
        height: 100%;
        overflow: hidden;
        position: relative;
      }

      &__line {
        position: absolute;
        left: 0;
        height: 1.5px;
        background-color: $color-brown-400;
        width: 100%;
        transition: all 0.4s;

        &_first {
          top: 0;
        }

        &_second {
          top: 50%;
          transform: translate(-10px, -50%);
        }

        &_third {
          bottom: 0;
          transform: translate(-25px, 0);
        }
      }

      &.is-open {
        padding: 10px 7px;

        .hamburger-btn {
          &__line {
            &_first {
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(30deg);
            }

            &_second {
              transform: translate(-100%, -50%);
            }

            &_third {
              left: 50%;
              bottom: 50%;
              transform: translate(-50%, 50%) rotate(-30deg);
            }
          }
        }
      }
    }
  }
}
