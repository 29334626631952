.section-hero-seco {
  padding: 260px 0 20px;

  @include mobile {
    padding: 180px 0 10px;
  }
  .heading {
    &__title {
      color: $color-brown-500;
      letter-spacing: 0.05em;
      font-family: $font__cinzel;
      position: relative;
      z-index: 1;
    }

    &__title-sub {
      font-size: 1.6rem;
      display: block;
      padding-left: 0.5em;
      margin-bottom: 3px;

      @include mobile {
        font-size: 1.2rem;
        transform-origin: left bottom;
        transform: scale(0.6);
        width: 0;
        white-space: nowrap;
      }
    }

    &__title-main {
      font-size: 7.4rem;
      display: block;
      line-height: 1;

      @include mobile {
        font-size: 4.2rem;
      }
    }
  }
}
