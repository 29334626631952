*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  font-size: $global-font-size;
  -webkit-font-smoothing: antialiased;
}

/*
  タイポグラフィの微調整
  4. アクセシブルなline-heightを追加
  5. テキストのレンダリングを改善
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: $base-font-size;
  font-family: $font__noto-serif;
  font-weight: 500;
  letter-spacing: 0;
  font-feature-settings: 'palt';
  text-rendering: optimizeLegibility;
  // line-height: $base-line-height;
  color: $base-font-color;

  @include mobile {
    // font-size: $base-font-size-small;
  }

  &.is-fixed {
    overflow: hidden;
  }
}

main,
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/*
  6. メディア要素のデフォルトを改善
*/
// img, picture, video, canvas, svg {
//   display: block;
//   max-width: 100%;
// }

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-style: normal;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  line-height: 2.3;
  text-align: justify;
}
img {
  vertical-align: bottom;
  border-style: none;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

/*
  form用resetCSS
*/

input[type='text'],
input[type='tel'],
input[type='email'] {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}
input[type='radio'],
input[type='checkbox'] {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  border-radius: 0;
}

button,
input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
