.reservation-btn {
  display: inline-block;
  width: 100%;
  min-height: 80px;
  padding: 0.5em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  color: $color-wht;
  background-color: $color-orange-500;

  @include mobile {
    min-height: 50px;
    font-size: 1.2rem;
  }
}

.sns-list {
  display: flex;

  &__item {
    width: 37px;

    @include mobile {
      width: 43px;
    }

    + .sns-list__item {
      margin-left: 15px;
    }
  }
}

.sns-icon {
  &__link {
    transition: opacity 0.4s;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

.more-btn {
  min-height: 80px;
  max-width: 450px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  font-size: 1.8rem;
  padding: 0.5em;
  background-color: $color-wht;
  border: 1px solid $color-gold-500;
  transition: opacity 0.4s;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  @include mobile {
    min-height: 50px;
    max-width: 245px;
    font-size: 1.2rem;
  }
}

.floating-btn-container {
  position: fixed;
  top: 20vh;
  right: 31px;
  z-index: 10;

  @include mobile {
    top: 18vh;
    right: 0;
  }

  &__inner {
    > * + * {
      margin-top: 23px;

      @include mobile {
        margin-top: 15px;
      }
    }
  }
}

.floating-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color: $color-brown-400;
  font-weight: 400;
  min-width: 40px;
  background-color: $color-wht;
  border-radius: 9999px;
  padding: 1.5em 0.5em;
  line-height: 1;
  transition: transform 0.4s;

  &:hover,
  &:focus {
    transform: scale(0.97);
  }

  @include mobile {
    font-size: 1.1rem;
    min-width: 35px;
    border-radius: 10px 0 0 10px;
    padding: 1em 1.25em 1em 0.8em;
  }

  &__text {
    writing-mode: vertical-lr;
  }

  &__icon {
    margin-bottom: 9px;
    display: block;

    @include mobile {
      margin-bottom: 5px;
    }

    &_pc {
      width: 22px;

      @include mobile {
        width: 11px;
      }
    }

    &_tel {
      width: 16px;

      @include mobile {
        width: 8px;
      }
    }
  }
}

.breadcrumbs {
  color: $color-brown-500;
  font-size: 1.4rem;

  @include mobile {
    font-size: 1.2rem;
  }

  &__list {
    display: flex;

    > *:not(:last-child) {
      margin-right: 60px;
      position: relative;

      @include mobile {
        margin-right: 30px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translate(50%, 50%);
        width: 40px;
        height: 1px;
        background-color: currentColor;

        @include mobile {
          right: -15px;
          width: 25px;
        }
      }
    }

    a {
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  &__link {
    padding: 0.5em;
    display: block;

    &_post-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.pagination {
  .nav-links {
    display: flex;
    justify-content: center;

    > * + * {
      margin-left: 5px;
    }

    a {
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  .page-numbers {
    font-size: 1.4rem;
    padding: 0.8em;

    @include mobile {
      font-size: 1.2rem;
    }
  }

  .prev,
  .next {
    width: 35px;
    padding: 0.2rem 5px 0;
    display: flex;
    align-items: center;

    @include mobile {
      width: 30px;
    }
  }
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.not-article {
  color: $color-brown-500;
  font-size: 1.6rem;
  margin: 150px 0 250px;
}
